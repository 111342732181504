export const STATUS = {
  init: 'init',
  loading: 'loading',
  error: 'error',
  succeeded: 'succeeded'
}

export const ERROR_MSG_DEFAULT = 'Oups! We`ve already running to fix it!'

export const DEFAULT_SPINNER_COLOR = '#3761f6'

export const AMPLITUDE_API_KEY = '2a0d8079f9f92eb6e943263f44949e2c'

export const AMPLITUDE_EVENTS = {
  authPageShow: 'auth_page_show',
  signInPageShow: 'sign_in_page_show',
  clickEmailSignUp: 'click_email_sign_up',
  setUpEmailAccountShow: 'set_up_email_account_show',
  clickContinueSignUpEmail: 'click_continue_sign_up_email',
  emailConfirmationPageShow: 'email_confirmation_page_show',
  emailConfirmationSuccess: 'email_confirmation_success',
  emailConfirmationFailed: 'email_confirmation_failed',
  clickLogInAuth: 'click_log_in_auth',
  clickContinueSignInEmail: 'click_continue_sign_in_email',
  clickGoogleAuth: 'click_google_auth',
  clickForgotMyPassword: 'click_forgot_my_password',
  setNewPasswordPageShow: 'set_new_password_page_show',
  clickChangePasswordByEmail: 'click_change_password_by_email',

  quizPageShow: 'quiz_page_show',
  mainProductPageShow: 'main_product_page_show',
  clickCreateProject: 'click_create_project',
  projectView: 'project_view',
  clickCopyText: 'click_copy_text',
  clickGetLinks: 'click_get_links',
}
