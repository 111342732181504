import React, { useEffect, useState } from "react";
import {ERRORS, PASSWORD_REGEX} from '../auth/consts';
import PopupCard from '../../components/PopupCard/PopupCard';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../features/account/accountSlice'
import Password from "../auth/components/Password/Password";
import { Navigate } from 'react-router-dom';
import { history } from '../../helpers/history';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENTS } from '../../consts';

export default function ResetPassword(props) {
  const dispatch = useDispatch();

  const authStatus = useSelector(state => state.account.status);
  const isAuthenticated = useSelector(state => state.account.isAuthenticated);
  const isConfirmed = useSelector(state => state.account.isConfirmed);
  const surveyShown = useSelector(state => state.account.surveyShown);
  const error = useSelector(state => state.account.error);

  const [password, setPassword] = useState('');
  const [passwordDublicate, setPasswordDublicate] = useState('');

  const [errors, setErrors] = useState({
    password: '',
    passwordDublicate: '',
  });

  useEffect(() => {
    amplitude.track(AMPLITUDE_EVENTS.setNewPasswordPageShow);
  }, []);

  function validateField(field, fieldName) {
    if (field === '') {
      
      setErrors((state) => {
        const newState = { ...state };
        newState[fieldName] = ERRORS.empty;

        return newState;
      });

      return ERRORS.empty;
    }

    if (!field.match(PASSWORD_REGEX)) {
      setErrors((state) => {
        const newState = { ...state };
        newState[fieldName] = ERRORS.incorrectPassword;

        return newState;
      });

      return ERRORS.incorrectPassword;
    }

    if (password !== '' && passwordDublicate !== '' && password !== passwordDublicate) {
      setErrors((state) => ({
        password: 'Passwords must match',
        passwordDublicate: 'Passwords must match',
      }));
    }

    setErrors((state) => {
      const newState = { ...state };
      newState[fieldName] = '';

      return newState;
    });

    return false;
  }

  function handlePasswordChange(e) {
    setErrors((state) => {
      const newState = { ...state };
      newState.password = '';

      return newState;
    });

    setPassword(e.target.value);
  }

  function handlePasswordDublicateChange(e) {
    setErrors((state) => {
      const newState = { ...state };
      newState.passwordDublicate = '';

      return newState;
    });

    setPasswordDublicate(e.target.value);
  }

  function handleSubmitClick(e) {
    e.preventDefault();

    const passwordValidationResult = validateField(password, 'password');
    const passwordDublicateValidationResult = validateField(passwordDublicate, 'passwordDublicate');
    
    if (passwordValidationResult || passwordDublicateValidationResult) {
      return;
    }

    amplitude.track(AMPLITUDE_EVENTS.clickChangePasswordByEmail);

    dispatch(resetPassword({
      token: window.location.search.substring(window.location.search.indexOf('?code=') + 6),
      new_password: password,
    }));
  }

  if (authStatus === 'succeeded' && isAuthenticated) {
    if (!isConfirmed) {
      return <Navigate to="/auth" state={{ from: history.location }} />
    }

    if (surveyShown) {
      return <Navigate to="/" state={{ from: history.location }} />
    }

    return <Navigate to="/surveys/about-me/" state={{ from: history.location }} />
  }

  return (
    <>
      {(error) &&
        <PopupCard type="error" msg={error}/>
      }

      <div className="auth">
        <div className="auth_showcase" />
        <div className="auth_content-wrapper">
          <div className="auth_content auth_content__static auth_content__center active">
            <div className="auth_content-top">
              <div className="auth_title">Set new password</div>
              <p className="auth_subtitle">Create a new password to your account</p>
            </div>
            <form className="auth_form">
              <div className={`auth_form-input ${errors.email ? 'error' : ''}`}>
                <label>Enter the password</label>
                <Password value={password} onChange={handlePasswordChange} placeholder="Password" />
                <div className="auth_form-errors">
                  {errors.password}
                </div>
              </div>
              <div className={`auth_form-input ${errors.passwordDublicate ? 'error' : ''}`}>
                <label>Repeat password</label>
                <Password value={passwordDublicate} onChange={handlePasswordDublicateChange} placeholder="Password" />
                <div className="auth_form-errors">
                  {errors.passwordDublicate}
                </div>
              </div>
              <div>
                <button onClick={handleSubmitClick}>Change password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
