import React, { useState, useRef, useEffect } from "react";
import * as amplitude from '@amplitude/analytics-browser';
import { client } from '../../../../helpers/client';
import PopupCard from '../../../../components/PopupCard/PopupCard';
import { AMPLITUDE_EVENTS } from '../../../../consts'
import { useDispatch, useSelector } from 'react-redux';
import { registerViaEmail } from '../../../../features/account/accountSlice';
import { ReactComponent as Logo } from '../../../../media/logo.svg';

import './EmailConfirmation.scss'

export default function EmailConfirmation(props) {
  const { email, onSwitch } = props;

  const intervalRef = useRef(0);
  
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState(null);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const apiError = useSelector(state => state.account.error)
  const isLoading =false;

  useEffect(() => {
    amplitude.track(AMPLITUDE_EVENTS.emailConfirmationPageShow);
  }, [])

  function validate() {  
    setError(code.length !== 6);
    return code.length !== 6;
  }

  function handleInput(e) {
    setError(false);
    
    const index = Number(e.target.dataset.number);
    const isDelete = e.keyCode === 8;
    
    if (e.key.match(/^[0-9]$/) || isDelete) {
      e.preventDefault();
      
      setCode((code) => {
        const newCode = [
          code[0] || '',
          code[1] || '',
          code[2] || '',
          code[3] || '',
          code[4] || '',
          code[5] || '',
        ];
  
        newCode[index] = isDelete ? '' : e.key;
        
        return newCode.join('');
      });
      
      setTimeout(() => {
        e.target.blur();
        if (index < 5) {
          document.querySelector(`.email-confirmation_input[data-number="${index + 1}"]`).focus();
        }
      }, 10);
    }

    if (isDelete && index > 0) {
      setTimeout(() => {
        e.target.blur();
        document.querySelector(`.email-confirmation_input[data-number="${index - 1}"]`).focus();
      }, 10);
    }
  }

  function handlePaste(e) {
    e.preventDefault();

    const value = (e.clipboardData || window.clipboardData).getData('text');
    
    if (/^\d+$/.test(value) && value.length === 6) {
      setCode(value);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (validate()) {
      return;
    }
    
    
    dispatch(registerViaEmail(code));
  }

  useEffect(() => {
    if (timer === 0) {
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, [])

  function handleSendAgain() {
    try {
      client.postNoContent('/account/reset-code');

      setTimer(60);

      intervalRef.current = setInterval(() => {
        setTimer((value) => value > 0 ? value - 1 : value);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {(apiError) &&
        <PopupCard type="error" msg={apiError}/>
      }

      <div className="auth_content-header">
        <Logo />
        <button className="button button__light" onClick={onSwitch}>Sign In</button>
      </div>

      <div className="email-confirmation">
        <h2 className="email-confirmation_title">Check your email for a code</h2>
        <p className="email-confirmation_subtitle">We’ve sent a 6-character code to <strong>“{email}“</strong>. The code expires shortly, so please enter is soon.</p>
      
        <div className={`email-confirmation_code ${error ? 'error' : ''}`}>
          <div className="email-confirmation_digits">
            <div className="email-confirmation_digits-group">
              <input className="email-confirmation_input" type="text" data-number="0" value={code[0] || ''} onKeyDown={handleInput} onPaste={handlePaste} disabled={isLoading} />
              <input className="email-confirmation_input" type="text" data-number="1" value={code[1] || ''} onKeyDown={handleInput} onPaste={handlePaste} disabled={isLoading} />
              <input className="email-confirmation_input" type="text" data-number="2" value={code[2] || ''} onKeyDown={handleInput} onPaste={handlePaste} disabled={isLoading} />
            </div>
            <span className="email-confirmation_digits-divider"></span>
            <div className="email-confirmation_digits-group">
              <input className="email-confirmation_input" type="text" data-number="3" value={code[3] || ''} onKeyDown={handleInput} onPaste={handlePaste} disabled={isLoading} />
              <input className="email-confirmation_input" type="text" data-number="4" value={code[4] || ''} onKeyDown={handleInput} onPaste={handlePaste} disabled={isLoading} />
              <input className="email-confirmation_input" type="text" data-number="5" value={code[5] || ''} onKeyDown={handleInput} onPaste={handlePaste} disabled={isLoading} />
            </div>
          </div>
          <button disabled={isLoading} onClick={handleSubmit}>Continue</button>
        </div>

        <div className="email-confirmation_caption">
          <span>Can’t find your code? Check your spam folder!</span>
          <span>Didn't get the code? {timer ? `${timer}s` : <button onClick={handleSendAgain}>Send again</button>}.</span>
        </div>
      </div>
      <div></div>
    </>
  );
}
