import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import edjsHTML from 'editorjs-html';

import { client } from '../../helpers/client'
import {replaceKeywordToLinkInText, removeLinks, removeLink, replaceLinkInText } from '../../helpers/link'
import { STATUS } from '../../consts';

const initialState = {
  status: 'init',
  error: '',
  project: {}
}

const edjsParser = edjsHTML();


export const fetchProject = createAsyncThunk('project/fetchProject', async(projectId) => {
  const response = await client.get('/projects/'+projectId+'/')
  return response.data
})


export const updateProject = createAsyncThunk('project/updateProject', async(project) => {
  const response = await client.patch('/projects/'+project.projectId+'/', {'text': project.newText})
  return response.data
})


export const updateKeywords = createAsyncThunk('project/updateKeywords', async(project) => {
  let cleanedNewText = removeLinks(project.newText)
  const response = await client.post(
    '/keywords/',
    {'text': edjsParser.parse(cleanedNewText).join(''), 'project_id': project.projectId}
  )
  return response.data
})


export const updateKeyword = createAsyncThunk('project/updateKeyword', async(keywordUpdates, { getState }) => {
  const state = getState();
  const response = await client.patch(
    '/keywords/' + keywordUpdates.id + '/',
    {
      'project_id': state.project.project.id,
      ...keywordUpdates,
    }
  )
  return response.data
})

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProject.pending, (state, action) => {
        state.status = STATUS.loading
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.project = action.payload;
        let newText = JSON.parse(state.project.text)
        newText = removeLinks(newText)
        state.project.keywords = state.project.keywords.filter((keyword) => !keyword.deleted);
        state.project.keywords.map((keyword) => {
          newText = replaceKeywordToLinkInText(newText, keyword.label, keyword.links[0].url)
        });
        
        state.project.text = newText;
        state.method = 'fetchProject';
        state.status = STATUS.succeeded;
      })
      .addCase(fetchProject.rejected, (state, action) => {
        state.status = STATUS.error
        state.error = action.error.message
      })
      .addCase(updateKeywords.pending, (state, action) => {
        state.status = STATUS.loading
      })
      .addCase(updateKeywords.fulfilled, (state, action) => {
        state.project = action.payload;
        let newText = JSON.parse(state.project.text)
        newText = removeLinks(newText)
        state.project.keywords = state.project.keywords.filter((keyword) => !keyword.deleted);
        state.project.keywords.map((keyword) => {
          newText = replaceKeywordToLinkInText(newText, keyword.label, keyword.links[0].url)
        });

        state.project.text = newText;
        state.method = 'updateKeywords';
        state.status = STATUS.succeeded;
      })
      .addCase(updateKeywords.rejected, (state, action) => {
        state.status = STATUS.error
        state.error = action.error.message
      })
      .addCase(updateKeyword.pending, (state, action) => {
        let keywordUpdates = action.meta.arg
        let newText = state.project.text;
        state.project.keywords = state.project.keywords.filter((keyword) => !keyword.deleted).map((keyword) => {
          if (keyword.id === keywordUpdates.id) {
            if ('deleted' in keywordUpdates) {
              newText = removeLink(state.project.text, keyword.links[0].url)
            }
            if ('links' in keywordUpdates){
              newText = replaceLinkInText(
                state.project.text,
                keyword.links[0].url,
                keywordUpdates.links[0].url
              )
            }
            return {
              ...keyword,
              ...keywordUpdates,
              status: STATUS.loading
            }
          }
          return keyword
        })
        state.project.text = newText
      })
      .addCase(updateKeyword.fulfilled, (state, action) => {
        let keywordUpdates = action.meta.arg
        let newText = state.project.text;
        state.project.keywords = state.project.keywords.filter((keyword) => !keyword.deleted).map((keyword) => {
          if (keyword.id === keywordUpdates.id) {
            let newLinks = keyword.links
            if ('name' in keywordUpdates){
              newLinks = action.payload.links
              newText = replaceLinkInText(
                state.project.text,
                keyword.links[0].url,
                newLinks[0].url
              )
            }
            return {
              ...keyword,
              ...keywordUpdates,
              links: newLinks,
              status: STATUS.succeeded
            }
          }
          return keyword
        })
        state.method = 'updateKeyword';
        state.project.text = newText;
      })
      .addCase(updateKeyword.rejected, (state, action) => {
        let keywordUpdates = action.meta.arg
        state.project.keywords = state.project.keywords.filter((keyword) => !keyword.deleted).map((keyword) => {
          if (keyword.id === keywordUpdates.id) {
            return {
              ...keyword,
              ...keywordUpdates,
              'status': STATUS.error,
              'error': action.error.message
            }
          }
          return keyword;
        })
      })
  }
})

export default projectSlice.reducer
