import React, { useState } from "react";
import PopupCard from '../../../../components/PopupCard/PopupCard';
import { client } from '../../../../helpers/client';

export default function ForgetPassword(props) {
  const {
    emailText,
  } = props;

  const [error, setError] = useState('');
  const [isSent, setIsSent] = useState(false);

  async function handleSubmitClick(e) {
    e.preventDefault();
    setError('');

    try {
      const response = await client.post('/forget-password', {
        email: emailText,
      })

      setIsSent(true);
      
      setError('');
    } catch (error) {
      setError(error);
    }
  }

  if(isSent) {
    return (
      <>
        <div></div>
        <div>
          <div className="auth_content-top">
            <div className="auth_title">Please check your email</div>
          </div>
        </div>
        <div></div>
      </>
    );
  }

  return (
    <>
      {(error) &&
        <PopupCard type="error" msg={error}/>
      }

      <div></div>
      <div>
        <div className="auth_content-top">
          <div className="auth_title">Reset password</div>
          <p className="auth_subtitle">Send reset link to <strong>{emailText}</strong>?</p>
        </div>
        <form className="auth_form">
          <div>
            <button onClick={handleSubmitClick}>Send</button>
          </div>
        </form>
      </div>
      <div></div>
    </>
  );
}
