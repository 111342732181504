import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditorJS from "@editorjs/editorjs";
import Header from '@editorjs/header';
import Marker from '@editorjs/marker';
import NestedList from '@editorjs/nested-list';
import Quote from '@editorjs/quote';
import RawHtml from '@editorjs/raw';
import SimpleImage from '@editorjs/simple-image';
import Delimiter from '@editorjs/delimiter';
import { useDebouncedCallback } from 'use-debounce';
import { updateProject, updateKeywords, updateKeyword } from '../../features/project/projectSlice'
import { STATUS } from '../../consts'
import { AMPLITUDE_EVENTS } from '../../consts';
import * as amplitude from '@amplitude/analytics-browser';
import {replaceKeywordToLinkInText, removeLinks, convertToRawText } from '../../helpers/link';
import { ReactComponent as Question } from '../../media/question.svg';

import Tooltip from '../tooltip/Tooltip';

const initEditor = (text, setText, ejInstance, saveContent) => {
  const editor = new EditorJS({
    autofocus: true,
    holder: 'editor',
    placeholder: 'Type or past your text here',
    data: text,
    onReady: async () => {
      ejInstance.current = editor;
      //editor.api.caret.setToLastBlock('end', 0);
    },
    onChange: async () => {
      let content = await editor.saver.save();
      setText(content);
      saveContent(content);
    },
    tools: {
      header: Header,
      marker: Marker,
      quote: Quote,
      image: SimpleImage,
      list: NestedList,
      raw: RawHtml,
      delimiter: Delimiter,
    },
  });
};

export default function TextEditor() {
  const { projectId } = useParams();
  const projectStatus = useSelector(state => state.project.status);
  const project = useSelector(state => state.project.project);
  const initialProjectText = useSelector(state => state.project.project.text);

  const [text, setText] = useState(Number(projectId) === Number(project.id) ? initialProjectText : '');

  const dispatch = useDispatch();
  const saveContent = useDebouncedCallback(
    (newText) => dispatch(updateProject({projectId: projectId, newText: JSON.stringify(newText)})), 2000
  );
  
  const ejInstance = useRef(null);
  const editorRef = useRef(null);

  if (editorRef.current) {
  }

  useEffect(() => {
    if (editorRef.current?.innerHTML !== '' && project.keywords) {
      const editorLinks = Array.from(editorRef.current.querySelectorAll('a[href]'))?.map((link) => link.innerText).filter((link) => link !== '');

      if (editorLinks.length === 0) {
        return;
      } 

      const deletedLinks = project.keywords.filter((keyword) => !keyword.deleted && !editorLinks.includes(keyword.label));

      deletedLinks.forEach((deletedLink) => {
        dispatch(updateKeyword({'id': deletedLink.id, 'deleted': true}));
      });
    }
  }, [dispatch, project.keywords, text]);
  
  useEffect(() => {
    if (ejInstance.current === null) {
      initEditor(text, setText, ejInstance, saveContent);
    }
    
    return async () => {
      dispatch(updateKeywords({projectId: projectId, newText: text}));
      
      if (ejInstance.current) {
        const content = await ejInstance.current.saver.save();
        dispatch(updateProject({projectId: projectId, newText: JSON.stringify(content)}))
      }

      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, []);

  useEffect(() => {
    if (ejInstance.current !== null && text) {
      let newText = removeLinks(text);
      project.keywords.map((keyword) => {
        newText = replaceKeywordToLinkInText(newText, keyword.label, keyword.links[0].url)
      });

      ejInstance?.current?.render(newText);
    }
    
  }, [project.keywords]);

  async function handleCopyClick() {
    const e = document.querySelector("#editor .codex-editor__redactor");
    const textNode = e.childNodes[0];
    const r = document.createRange();
    const startIndex = 0;
    r.setStart(e, startIndex);
    r.setEnd(e, e.children.length);
    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(r);
    document.execCommand("copy");

    amplitude.track(AMPLITUDE_EVENTS.clickCopyText);
  }

  async function handleCopyTextClick() {
    const copyText = convertToRawText(text);

    navigator.clipboard.writeText(copyText).then(function() {
      console.log('Copying to clipboard was successful!');
    }, function(err) {
      console.error(' Could not copy text: ', err);
    });

    amplitude.track(AMPLITUDE_EVENTS.clickCopyText);
  }

  async function handleGetLinksClick() {
    dispatch(updateKeywords({projectId: projectId, newText: text}));
    amplitude.track(AMPLITUDE_EVENTS.clickGetLinks);
  }
  
  return (
      <div className="editor-body">
        <div className="editor-wrapper">
          <div className="editor-input" id="editor" ref={editorRef}></div>
        </div>
        {([STATUS.succeeded, STATUS.error].includes(projectStatus)) &&
          <div className="editor-control not-scrolled-bottom" id="editor-control">
            <div className="editor-control_buttons">
              <button className="button button__light" onClick={handleCopyClick} disabled={text?.blocks?.length === 0} title="Copy text with links hidden under keywords.">Copy HTML text</button>
              <button className="button button__light" onClick={handleCopyTextClick} disabled={text?.blocks?.length === 0} title="Copy text with visible links next to each keyword"> Copy text with URLs</button>

              <Tooltip content={
                <>
                  <h3>How to choose the correct copy format:</h3>
                  <p>
                    When you click <strong>“Copy HTML Text”</strong>, the text is copied in HTML format, with links embedded within keywords or phrases. This option is ideal if the platform (e.g., Medium, Substack, LinkedIn, and others) supports HTML formatting and displays links correctly.
                  </p>
                  <p>
                  When you click <strong>“Copy text with URLs”</strong>, a full URL link is added next to each keyword. This format is useful when platforms (such as Facebook, Twitter (X), YouTube) don’t support HTML. In these cases, they remove the HTML formatting upon pasting and leave only plain text. In such cases, use the format with the full URL next to the keywords so that links display correctly.
                  </p>
                </>
              }>
                <Question className="editor-control_tooltip" />
              </Tooltip>
            </div>
            <div>
              <button className="button" onClick={handleGetLinksClick} disabled={!text?.blocks?.length || text?.blocks?.length === 0}>Get Links</button>
            </div>
          </div>
        }
      </div>
    )
  }
