import React from "react";
import * as amplitude from '@amplitude/analytics-browser';
import { useDispatch } from 'react-redux';
import GoogleLogo from '../../../../media/google.png';
import { login } from '../../../../features/account/accountSlice'
import { useGoogleLogin } from '@react-oauth/google';
import { AMPLITUDE_EVENTS } from '../../../../consts'

export default function GoogleLogin(props) {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault();

    googleLogin();
  }

  const googleLogin = useGoogleLogin({
    onSuccess: credentialResponse => {
      amplitude.track(AMPLITUDE_EVENTS.clickGoogleAuth)
      if (credentialResponse.code){
        dispatch(login(credentialResponse.code))
      }
    },
    flow: 'auth-code',
  });

  return (
    <>
      <button className="auth_google-button" onClick={handleClick}>
        <img className="auth_google-button-icon" src={GoogleLogo} alt="" />
        Continue with Google
      </button>
    </>
  )
}
