import React, { useState } from "react";
import { ReactComponent as Icon } from '../../../../media/eye.svg';

import './Password.scss';

export default function Password(props) {
  const {
    value,
    onChange,
    placeholder
  } = props;

  const [type, setType] = useState('password');

  function switchType() {
    setType((value) => value === 'password' ? 'text' : 'password');
  }

  return (
    <div className="password">
      <input type={type} value={value} onChange={onChange} placeholder={placeholder} />
      <svg  className="password_icon" onClick={switchType} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12Z" stroke="black" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 12C3.6 7.903 7.336 5 12 5C16.664 5 20.4 7.903 22 12C20.4 16.097 16.664 19 12 19C7.336 19 3.6 16.097 2 12Z" stroke="black" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>
  );
}
