import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { ClipLoader } from 'react-spinners';
import { ReactComponent as KeywordTrashIcon } from '../../media/keywordTrash.svg';
import { ReactComponent as MoreLinkIcon } from '../../media/moreLinkIcon.svg';
import { ReactComponent as SearchLinkIcon } from '../../media/searchLinkIcon.svg';
import { updateKeyword } from '../../features/project/projectSlice';
import { STATUS } from '../../consts'
import PartnerLink from './PartnerLink/PartnerLink';

import './PartnerLinkCard.scss';


function PartnerLinkCardSearch({ keyword, setCardType, dispatch }) {
  let content = null;
  
  const [searchText, setSearchText] = useState('');

  const debounced = useDebouncedCallback(
    (searchText) => {
      dispatch(updateKeyword({'id': keyword.id, 'name': searchText}));
    },
    1000
  );

  if (keyword.status === STATUS.loading) {
    content = <ClipLoader/>
  } else if (keyword.status === STATUS.error) {
    content = (
      <span id="result-placeholder" className="partner-link-card_search-placeholder">
        {keyword.error}
      </span>
    )
  } else if (!searchText) {
    content = (
      <span id="result-placeholder" className="partner-link-card_search-placeholder">
        Write something
      </span>
    )
  } else {
    content = (
      <div className="partner-link-card_search-results partner-link-card_link" id="search-results">
        {keyword.links.map((link, index) => {
          var linkKey = keyword.id + '-' + index + '-search';
          return <PartnerLink link={false} isSelected={true} linkKey={linkKey} action={null} />
        })}
      </div>
    )
  }
  return (
    <>
      <div className="partner-link-card_search" id="search-container">
        <div className="partner-link-card_search-content">
          <SearchLinkIcon width="16" height="17"/>
          <input
            className="partner-link-card_search-input"
            type="text"
            placeholder="Search by other words"
            id="search-input"
            value={searchText}
            onChange={e => {
              let data = e.target.value;
              setSearchText(data);
              debounced(data)
            }}
          />
          <MoreLinkIcon onClick={() => {setCardType("detailed")}}/>
        </div>
        <div className="partner-link-card_link">
          {content}
        </div>
      </div>
    </>
  )
}

export default function PartnerLinkCard(keywordId) {
  const [cardType, setCardType] = useState('short');
  const dispatch = useDispatch()
  const keyword = useSelector(state => state.project.project.keywords[keywordId.keywordId]);
  const keywordStatus = useSelector(state => state.project.project.keywords[keywordId.keywordId].status);

  function handleMoreClick() {
    if (cardType !== 'full') {
      setCardType('full');
    } else {
      setCardType('detailed');
    }
  }

  return (
    <div className={`partner-link-card ${cardType !== 'short' ? 'selected' : ''} ${cardType === 'full' ? 'opened' : ''}`}>
      <div className="partner-link-card_keyword">
        <div className="partner-link-card_keyword-wrapper" onClick={() => { setCardType(cardType !== 'short' ? ' short' : 'detailed')}}>
          <a href={keyword.links[0].url} target="_blank" rel="noreferrer">{keyword.label}</a>
        </div>
        <KeywordTrashIcon className="partner-link-card_remove" onClick={() => {
          dispatch(updateKeyword({'id': keyword.id, 'deleted': true}))
        }}/>
      </div>

      {cardType === 'search' && <PartnerLinkCardSearch keyword={keyword} setCardType={setCardType} dispatch={dispatch} />}

      {cardType !== 'search' && (
        <div className="partner-link-card_link">
          {cardType === 'short' && <PartnerLink link={keyword.links[0]} isSelected={false} linkKey={keyword.id + '-' + 0} action={null} />}
          {cardType !== 'short' && keyword.links.map((link, index) => {
            const linkKey = keyword.id + '-' + index;

            if (index === 0){
              return <PartnerLink link={link} isSelected={true} linkKey={linkKey} action={null} />
            }

            if (cardType === 'detailed' && index >= 3) {
              return null
            }

            function handleLinkClick() {
              let newLinks = [keyword.links[index]].concat(
                keyword.links.filter((link, i) => {return i !== index}
              ));
              dispatch(updateKeyword({'id': keyword.id, 'links': newLinks}));
            }

            return <PartnerLink link={link} isSelected={false} linkKey={linkKey} action={handleLinkClick} />
          })}
        </div>
      )}

      <div className="partner-link-card_footer">
        <button className="partner-link-card_more" onClick={handleMoreClick}>
          <MoreLinkIcon/>
          {cardType === 'full' ? 'Less links' : 'More Links'}
          </button>
        <button className="partner-link-card_search-button" onClick={() => {setCardType('search')}}>
          <SearchLinkIcon/>
        </button>
      </div>
    </div>
  )
}