import React, { useState, useEffect } from "react";
import * as amplitude from '@amplitude/analytics-browser';
import Header from '../../components/header/Header'
import { client } from '../../helpers/client'
import { AMPLITUDE_EVENTS } from '../../consts'
import './Survey.css'


export default function SurveyPage() {
  const [isOpenedAmplitudeTracked, setIsOpenedAmplitudeTracked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.post('/surveys')
    }
    fetchData().catch(console.error);
  }, []);

  if (!isOpenedAmplitudeTracked) {
    amplitude.track(AMPLITUDE_EVENTS.quizPageShow);
    setIsOpenedAmplitudeTracked(true)
  }
  
  return (
    <>
      <Header/>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScK0_jY3V6zyKz7eZFp_4fEfw013SrRYJojK5YaPfh5zeNorA/viewform?embedded=true" className='surveyBlock' frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </>
  )
}