import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../media/CloseIcon.svg';

import './PopupCard.scss'

export default function PopupCard(props) {
  const { type } = props;

  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={`popup-card ${isOpen ? 'open' : ''} popup-card__${type}`} open={isOpen}>
      <div className="popup-card_content">
        {type === 'error' && (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34783 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34783 22 12 22Z" stroke="#F63737" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 16L16 8M8 8L16 16" stroke="#F63737" strokeWidth="1.5" strokeLinecap="round" strokeLlinejoin="round"/>
          </svg>
        )}
        <span>{props.msg.replace('Error:', '')}</span>
      </div>
      <CloseIcon onClick={() => {setIsOpen(false)}} style={{cursor: 'pointer'}}/>
    </div>
  )
}