import React, { useState } from 'react';
import './tooltip.scss'


export default function Tooltip({ content, children }) {
  const [isOpened, setIsOpened] = useState(false);

  async function handleClick() {
    setIsOpened((previous) => !previous)
  }
  
  return (
      <div className={`tooltip ${isOpened ? 'active' : ''}`} onClick={handleClick}>
        <div className="tooltip_content">
          {content}
        </div>
        {children}
      </div>
    )
  }
