import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../features/account/accountSlice'
import { ReactComponent as Logo } from '../../media/logo.svg';
import './Header.css';
import Popup from 'reactjs-popup';

export default function Header() {
  const [expanded, setExpanded] = useState(false)
  const onExpandedChanged = (e) => setExpanded(!expanded)
  const name = useSelector(state => state.account.name);
  const balance = useSelector(state => state.account.balance);
  const pictureURL = useSelector(state => state.account.picture);
  const dispatch = useDispatch();
  const [popupShown, setPopupShown] = useState(false)
  return (
    <header className="header" id="header-component">
      <div className="header-container">
        <div className="header-left-side">
          <div className="header-logo">
            <Logo/>
          </div>
          <div className="nav-menu" id="navigation-menu-component">
            <NavLink to='../' className="nav-link" id="navigation-link-company">Projects</NavLink>
          </div>
        </div>
        <div className="header-right-side">
          <div className="balance-button" onClick={() => {setPopupShown(true)}}>
            { balance } $
          </div>
          <div className="header-profile" onClick={onExpandedChanged}>
            {pictureURL ? (
              <img
                className="profile-image"
                width="32"
                height="32"
                src={ pictureURL }
                alt="avatar"
                referrerPolicy="no-referrer"
              />
            ) : <div className="profile-image profile-image__text">{ name ? name[0] : ''}</div>}
            <div className="profile-name">{ name }</div>
            {(!expanded) &&
              <div className="profile-expand-btn" />
            }
            {expanded &&
            <>
              <div className="profile-expand-btn expand" />
                <div className="profile-actions expand" id="profile-action">
                  <div className="profile-info expand">
                  {pictureURL ? (
                      <img
                        className="profile-image"
                        width="32"
                        height="32"
                        src={ pictureURL }
                        alt="avatar"
                        referrerPolicy="no-referrer"
                      />
                    ) : <div className="profile-image profile-image__text">{ name ? name[0] : ''}</div>}
                    <div className="profile-details expand">
                    <div className="profile-bold-row expand">
                      <span>{name}</span>
                    </div>
                    <div className="profile-tag expand">@{name}</div>
                  </div>
                </div>
                <div className="divider expand"></div>
                <div className="action-wrapper expand">
                  <div className="profile-action red-action expand" onClick={() => {
                    dispatch(logout())
                  }}>
                    Log out
                  </div>
                </div>
              </div>
            </>
            }
          </div>
        </div>
      </div>
    </header>
  )
}