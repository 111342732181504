import { ERROR_MSG_DEFAULT } from '../consts'


function getServiceUrl() {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    return 'http://localhost:8000/api'
  }
  
  if (window.location.hostname === 'app-test.linkify.ai') {
    return 'http://app-test.linkify.ai/api';
  }

  return 'https://app.linkify.ai/api'
};

export async function client(endpoint, { body, ...customConfig } = {}, noContent = false) {
  const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
  const service_url = getServiceUrl();

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    mode: "cors",
    credentials: 'include',
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

 
  try {
    const response = await window.fetch(service_url + endpoint, config);

    if (noContent) {
      return;
    }

    const data = await response.json();
    if (response.ok) {
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      }
    }

    throw new Error(response.status >= 500 ? ERROR_MSG_DEFAULT : data.detail || response.statusText)
  } catch (error) {
    return Promise.reject(String(error) || ERROR_MSG_DEFAULT)
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' })
}

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'POST' })
}

client.postNoContent = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'POST' }, true);
}

client.patch = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'PATCH' })
}
