import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {GridLoader} from 'react-spinners';
import * as amplitude from '@amplitude/analytics-browser';
import Header from '../../components/header/Header'
import { fetchProjects } from '../../features/projects/projectsSlice'
import ProjectCard from '../../components/ProjectCard/ProjectCard'
import AddNewProjectButton from '../../components/AddNewProjectButton/AddNewProjectButton'
import PopupCard from '../../components/PopupCard/PopupCard'
import { STATUS, DEFAULT_SPINNER_COLOR } from '../../consts';
import { AMPLITUDE_EVENTS } from '../../consts';
import EmptyImage from './images/empty.svg';
import './Projects.scss';


export default function ProjectsPage() {
  const projectsStatus = useSelector(state => state.projects.status);
  const projects = useSelector(state => state.projects.projects);
  const [isOpenedAmplitudeTracked, setIsOpenedAmplitudeTracked] = useState(false);
  const dispatch = useDispatch();
  const error = useSelector(state => state.project.error)

  useEffect(() => {
    dispatch(fetchProjects())
  }, [])

  if (projectsStatus === STATUS.loading) {
    return (
      <>
        <Header/>
        <div className="projects">
          <div className="page-title">Projects</div>
          <div className="projects_grid">
            <GridLoader color={DEFAULT_SPINNER_COLOR}/>
          </div>
        </div>
      </>
    )
  }
  if (!isOpenedAmplitudeTracked) {
    amplitude.track(AMPLITUDE_EVENTS.mainProductPageShow);
    setIsOpenedAmplitudeTracked(true)
  }

  return (
    <>
      <Header/>
      {(projectsStatus === STATUS.error) &&
        <PopupCard type="error" msg={error}/>
      }
      <div className="projects">
        <div className="projects_header">
          <div className="projects_title">Projects</div>
          <AddNewProjectButton />
        </div>

        {projects.length === 0 && (
          <div className="projects_empty">
            <img src={EmptyImage} alt="" />
            <div>
              <h3 className="projects_empty-title">Create a project and add content</h3>
              <p className="projects_empty-description">
                To maximize your earnings from affiliate links, focus on creating engaging and valuable content. Share reviews, tutorials, or informative articles that resonate with your audience. 
              </p>
            </div>
            <AddNewProjectButton />
          </div>
        )}
        
        {projects.length > 0 && (
          <div className="projects_grid">
            {projects.map((project, index) => (
              <ProjectCard key={`${project.id}-${index}`} project={project} />
            ))}
          </div>
        )}
      </div>
    </>
  )
}
