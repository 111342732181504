import React from "react";
import { NavLink, Navigate } from "react-router-dom";
import { ReactComponent as LinkIcon } from '../../media/link.svg';
import { ReactComponent as CalendarIcon } from '../../media/calendar.svg';
import { format } from 'date-fns';

import './ProjectCard.scss';

export default function ProjectCard(props) {
  const { project } = props;

  return (
    <NavLink to={'/projects/'+project.id+'/'} className="project-card">
      <div className="project-card_title">{project.name}</div>
      <div className="project-card_description">
        {project.short_text}
      </div>
      <div className="project-card_footer">
        <div className="project-card_count">
          <LinkIcon/>
          {project.count_links} links
        </div>
        <span className="project-card_date">
          <CalendarIcon />
          {format(project.created_at, 'dd.MM.yyyy')}
        </span>
      </div>
    </NavLink>
  )
}