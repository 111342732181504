import $ from 'jquery';

export function replaceKeywordToLinkInText(editorText, keyword, link){
  if (!editorText) {
    return editorText
  }

  function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }

  return {
    ...editorText,
    blocks: editorText.blocks.map((block) => {
      const keywordRegExp = new RegExp('(|[\\s.,!?:\\(\\)\\{\\}\\/>]|)('+ escapeRegExp(keyword)+')([\\s.,!?:\\(\\)\\{\\}\\/<]|$)', "gi")
      const stringToReplace = `<a href="${link}" class="linkifyLink" rel=”sponsored”>$2</a>`;
      return {
        ...block,
        data: {
          ...block.data,
          text: block.data.text.replaceAll(keywordRegExp, "$1"+stringToReplace+"$3")
        }
      }
    })
  }
}

export function convertToRawText(editorText){
  if (!editorText) {
    return editorText
  }

  let resultText = '';

  function parseToText(html) {
    const text = $.parseHTML(`<p>${html}</p>`);


    $(text[0]).find('a').map((_index, target) => {
      const { href, innerText } = target;
      
      if (innerText) {
        target.after(`${innerText} (${href})`);
      }
    })
      
    $(text[0]).find('a').replaceAll().contents().unwrap();
    $(text[0]).find('a').remove();

    resultText += text[0].innerHTML + '\n' + '\n';
  }
  
  editorText.blocks.forEach((block) => {
    const { text, caption, items } = block.data;

    if (items) {
      function parseItems(items) {
        items.forEach((item) => {
          if (item.content) {
            parseToText(item.content);
          }

          if (item.items.length > 0) {
            parseItems(item.items);
          }
        });
      }
      
      parseItems(items);
      return;
    }
    

    if (text) {
      parseToText(text);
    }

    if (caption) {
      parseToText(caption);
    }
  })

  return resultText;
}

export function removeLinks(editorText){
  if (!editorText) {
    return editorText
  }

  return {
    ...editorText,
    blocks: editorText.blocks.map((block) => {
      let text = $.parseHTML('<p>' + block.data.text + '</p>');
      
      $(text[0]).find('a[class^="linkifyLink"]').contents().unwrap();
      $(text[0]).find('a[class^="linkifyLink"]').remove();

      return {
        ...block,
        data: {
          ...block.data,
          text: text[0].innerHTML,
        }
      }
    })
  }
}


export function removeLink(editorText, linkToRemove){
  if (!editorText) {
    return editorText
  }

  return {
    ...editorText,
    blocks: editorText.blocks.map((block) => {
      let text = $.parseHTML('<p>' + block.data.text + '</p>')
      $(text[0]).find('a.linkifyLink[href^="'+ linkToRemove + '"]').contents().unwrap()

      return {
        ...block,
        "data": {
          ...block.data,
          "text": text[0].innerHTML
        }
      }
    })
  }
}


export function replaceLinkInText(editorText, oldLink, newLink){
  if (!editorText) {
    return editorText
  }

  return {
    ...editorText,
    blocks: editorText.blocks.map((block) => {
      let  linkRegExp = `<a href="${oldLink}" class="linkifyLink" rel=”sponsored”>`;
      let  stringToReplace = `<a href="${newLink}" class="linkifyLink" rel=”sponsored”>`;

      return {
        ...block,
        "data": {
          ...block.data,
          "text": block.data.text.replaceAll(linkRegExp, stringToReplace)
        }
      }
    })
  }
}
